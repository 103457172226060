.table-responsive {
  position: relative;
  th {
    &.th-0 {
      &.isFreezeFirstColumn {
        position: sticky;
        z-index: 10;
        left: 0;
        min-width: 8rem;
        max-width: 8rem;
        background: white;
      }
    }
    &.th-1 {
      &.isFreeze2ndColumn {
        position: sticky;
        z-index: 10;
        min-width: 8rem;
        max-width: 8rem;
        background: white;
        left: calc(8rem + 26px);
      }
    }
  }
  td {
    &.td-0 {
      &.isFreezeFirstColumn {
        position: sticky;
        z-index: 10;
        background: white;
        left: 0;
        min-width: 8rem;
        max-width: 8rem;
      }
    }
    &.td-1 {
      &.isFreeze2ndColumn {
        position: sticky;
        z-index: 10;
        left: 8rem;
        min-width: 8rem;
        max-width: 8rem;
        background: white;
        left: calc(8rem + 26px);
      }
    }
  }
}
